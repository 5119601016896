<template>
  <div>
    <CRow>
      <CCol md="12">
        <CCard>
          <CCardHeader>
            <CIcon name="cil-notes" />
            クーポン登録/編集
          </CCardHeader>
          <CCardBody>
            <CForm>
              <div class="form-group form-row">
                <CCol sm="3">クーポンID</CCol>
                <CCol v-if="!this.isNew" sm="9">{{this.coupon.jan_code}}{{ ('000000' + this.coupon.couponCode).slice(-6) }} (JANコード＋クーポン番号）</CCol>
                <CCol v-if="this.isNew" sm="9">自動採番されます</CCol>
              </div>
              <CInput
                label="クーポン名"
                placeholder="クーポン名..."
                horizontal
                v-model="coupon.title"
                :lazy="true"
                :isValid="checkIfValid('title')"
                invalidFeedback="クーポン名を入力してください。"
              />
              <CInput
                label="商品規格"
                placeholder="商品規格..."
                horizontal
                v-model="coupon.subTitle"
              />
              <div class="form-group form-row">
                <CCol tag="label" sm="3" class="col-form-label">
                  カテゴリ
                </CCol>
                <CCol sm="9" class="form-inline">
                  <CSelect
                    horizontal
                    :options="genrePullDown"
                    placeholder="Please select"
                    :value.sync="genre_id"
                    @update:value="onUpdateGenre($event)"
                  />
                  <CSelect
                    horizontal
                    :options="categoryPullDown"
                    placeholder="Please select"
                    style="padding-left: 10px;"
                    :value.sync="category_id"
                  />
                </CCol>
              </div>
              <div class="form-group form-row">
                <CCol tag="label" sm="3" class="col-form-label">
                  酒類
                </CCol>
                <CCol sm="9" class="form-inline">
                  <input type="checkbox"
                    :checked="coupon.isAlcohol"
                    v-model="coupon.isAlcohol"
                  />
                </CCol>
              </div>
              <CSelect
                label="メーカー名"
                horizontal
                :options="makerPullDown"
                placeholder="Please select"
                :value.sync="coupon.maker_id"
                @update:value="onUpdateMaker($event)"
              />
              <div class="form-group form-row">
                <CCol tag="label" sm="3" class="col-form-label">
                  卸発行クーポン
                </CCol>
                <CCol sm="9" class="form-inline">
                  <input type="checkbox"
                    :checked="coupon.wholesaleFlag"
                    v-model="coupon.wholesaleFlag"
                    :disabled="disabledWholesaleFlag"
                  />
                  <CSelect
                    horizontal
                    :options="wholesalePullDown"
                    placeholder="Please select"
                    v-show="coupon.wholesaleFlag"
                    :value.sync="coupon.wholesale_id"
                    @update:value="onUpdateWholesale($event)"
                  />
                </CCol>
              </div>
              <div class="form-group form-row">
                <CCol tag="label" sm="3" class="col-form-label">
                  小売発行クーポン
                </CCol>
                <CCol sm="9" class="form-inline">
                  <input type="checkbox"
                    :checked="coupon.distributionFlag"
                    v-model="coupon.distributionFlag"
                    :disabled="!isAdmin()"
                  />
                  <CSelect
                    horizontal
                    :options="distributionPullDown"
                    placeholder="Please select"
                    v-show="coupon.distributionFlag"
                    :value.sync="distributionId"
                  />
                </CCol>
              </div>

              <!-- <CInput
                label="メーカー名（固定する場合）"
                placeholder="Makername"
                horizontal
                disabled
              /> -->
              <CTextarea
                label="クーポン説明"
                placeholder="クーポン説明文..."
                horizontal
                rows="9"
                v-model="coupon.description"
              />
              <div style="margin:25px 0 20px 0;">
                <CInputFile
                  label="クーポン画像"
                  horizontal
                  @change="onChangeImage($event)"
                />
                <CImg
                  :src="fireStorageImageUrl"
                  class="d-block col-sm-6 offset-sm-3"
                  rows="mb-2"
                />
                <div class="form-group form-row">
                  <CCol tag="label" sm="3" class="col-form-label"> </CCol>
                  <CCol sm="9" class="form-inline">
                    {{ this.getDisplayImageName() }}
                  </CCol>
                </div>
              </div>
              <CInput
                label="有効期限（開始日）"
                type="date"
                horizontal
                v-model="coupon.start_date"
                :lazy="true"
                :isValid="checkIfValid('start_date')"
                invalidFeedback="開始日を入力してください。"
              />
              <CInput
                label="有効期限（終了日）"
                type="date"
                horizontal
                v-model="coupon.end_date"
                :lazy="true"
                :isValid="checkIfValid('end_date')"
                invalidFeedback="終了日を入力してください。"
              />
              <CInput
                label="JANコード"
                placeholder="1234567890123"
                horizontal
                v-model="coupon.jan_code"
                :lazy="true"
                :isValid="checkIfValid('jan_code')"
                invalidFeedback="8桁または13桁の正しいコードを入力してください。"
              />
              <CInput
                label="割引金額"
                placeholder="0000"
                horizontal
                v-model="coupon.discount"
                :lazy="true"
                :isValid="checkIfValid('discount')"
                invalidFeedback="正しい割引額を入力してください。"
              />
              <CInput
                label="発行枚数（0は無制限）"
                placeholder="0000"
                horizontal
                v-model="coupon.limitNum"
                :lazy="true"
                :isValid="checkIfValid('limitNum')"
                invalidFeedback="0以上の数字を入力してください。"
              />
              <CTextarea
                label="注意事項"
                placeholder="テキスト..."
                horizontal
                rows="9"
                v-model="coupon.notice"
              />
              <div class="form-group form-row">
                <CCol tag="label" sm="3" class="col-form-label">
                  再取得可能<br/>
                  「ON」→「チェックを入れる」と、再取得可能。<br/>
                  「OFF」→「チェックを入れない」と、再取得不可となります。
                </CCol>
                <CCol sm="9" class="form-inline">
                  <input type="checkbox"
                    :checked="coupon.reacquisitionFlag"
                    v-model="coupon.reacquisitionFlag"
                  />
                </CCol>
              </div>
              <div class="form-group form-row">
                <CCol tag="label" sm="3" class="col-form-label">
                  １精算につき、買上げ全商品対象<br/>
                  「ON」→「チェックを入れる」と、1精算につき買い上げ全点数割引き。<br/>
                  「OFF」→「チェックを入れない」と、1精算につき1点限り割引きとなります。
                </CCol>
                <CCol sm="9" class="form-inline">
                  <input type="checkbox"
                    :checked="coupon.allPerCustomerFlag"
                    v-model="coupon.allPerCustomerFlag"
                  />
                </CCol>
              </div>
              <CInput
                label="優先番号"
                placeholder=""
                horizontal
                v-model="coupon.priorityNumber"
                :lazy="true"
                :isValid="checkIfValid('priorityNumber')"
                invalidFeedback="1以上の数字を入力してください。"
                v-show="false"
              />
              <CSelect
                label="公開ステータス"
                horizontal
                :options="options"
                placeholder="選択してください"
                v-model="coupon.status"
                @update:value="onUpdateStatus($event)"
              />
              <div class="form-group form-row" v-if="this.distributions.length > 0">
                <br/>
                店舗登録
              </div>
              <div class="form-group form-row" v-for="(item, index) in this.distributions" v-bind:item="item" v-bind:index="index" v-bind:key="item.id">
                <CCol tag="label" sm="3" class="form-inline col-form-label" v-show="!isDistribution()">
                  <div style="display: flex;">
                    <input type="checkbox"
                      :id="item.useId"
                      :label="item.name"
                      :checked="item.hasCoupon"
                      v-model="item.hasCoupon"
                    />
                    <label style="padding-right: 10px; padding-left: 5px;" :for=item.useId>{{item.name}}</label>
                  </div>
                </CCol>
                <CCol tag="label" sm="3" class="form-inline col-form-label" v-show="isDistribution()">
                  {{item.name}}
                </CCol>
                <CCol tag="label" sm="1" class="form-inline col-form-label">
                  <CInputCheckbox
                    :disabled="!item.hasCoupon"
                    label="すべて選択"
                    :checked="item.stores.every(v => v.hasCoupon)"
                    @update:checked="onClickedAllStoreCoupon($event, item.id)"
                  />
                </CCol>
                <CCol sm="8" class="form-inline">
                  <div class="form-group" style="padding-left: 15px;" v-for="(item2, index2) in item.stores" v-bind:item2="item2" v-bind:index2="index2" v-bind:key="item2.id">
                    <input type="checkbox"
                      :disabled="!item.hasCoupon"
                      :id=item2.id
                      :checked=item2.hasCoupon
                      v-model=item2.hasCoupon
                    />
                    <label style="padding-right: 10px; padding-left: 5px;" :for=item2.id>{{item2.name}}</label>
                  </div>
                </CCol>
              </div>
              <CRow class="align-items-center" v-show="this.distributions.length > 0 && isDistribution() && !this.distributionEditFlag">
                <CCol col="6" class="text-left">
                  <CButton color="info" @click="submitStore" class="w-50">店舗を登録する</CButton>
                </CCol>
              </CRow>
            </CForm>
          </CCardBody>
          <CCardFooter v-show="!isDistribution() || this.distributionEditFlag">
            <CRow class="align-items-center">
              <!-- <CButton type="submit" size="sm" color="primary"><CIcon name="cil-circle-check"/>登録する</CButton>
            <CButton type="reset" size="sm" color="danger"><CIcon name="cil-ban"/>リセット</CButton> -->
              <CCol col="6" class="text-left">
                <CButton color="info" @click="submit" class="w-50">登録する</CButton>
              </CCol>
              <CCol col="6" class="text-right" v-if="isAdmin()">
                <CButton
                  v-if="!this.isNew"
                  color="danger"
                  @click="dangerModal = true"
                  class="mr-1"
                  >削除する</CButton>
              </CCol>
            </CRow>
          </CCardFooter>
        </CCard>
      </CCol>
    </CRow>
    <CModal
      title="クーポン削除"
      color="danger"
      :show.sync="dangerModal"
      @ok="onClickedDelete()"
    >
      {{ coupon.title }}を本当に削除しますか？
      <template #footer>
        <CButton @click="dangerModal = false" color="secondary">キャンセル</CButton>
        <CButton @click="onClickedDelete()" color="danger">OK</CButton>
      </template>
    </CModal>
    <CModal
      title="クーポン削除"
      color="danger"
      :show.sync="warnModal"
      @ok="onClickedDelete()"
    >
      {{ coupon.title }}は、実績登録されており、削除すると実績との紐付けがなくなります。<br/>
      本当に削除しますか？
      <template #footer>
        <CButton @click="warnModal = false" color="secondary">キャンセル</CButton>
        <CButton @click="onClickedDelete()" color="danger">OK</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import Vue from "vue";
import firebase from '@firebase/app';
import { uuid } from "vue-uuid";
import { validationMixin } from "vuelidate";
import { required, numeric, minValue } from "vuelidate/lib/validators";
import { UserRole } from '@/common/const';

const checkJanCodeLength = function(barcodeStr) {
  if (String(barcodeStr).length != 8 && String(barcodeStr).length != 13) {
    return false;
  }
  barcodeStr = ('00000' + barcodeStr).slice(-13);
  let evenNum = 0, oddNum = 0;
  for (var i = 0; i < barcodeStr.length - 1; i++) {
      if (i % 2 == 0) {
          oddNum += parseInt(barcodeStr[i]), 10;
      } else {
          evenNum += parseInt(barcodeStr[i], 10);
      }
  }
  let checkNum = 10 - parseInt((evenNum * 3 + oddNum).toString().slice(-1), 10);
  if (checkNum % 10 === 0) {
    return 0 === parseInt(barcodeStr.slice(-1), 10);
  } else {
    return checkNum === parseInt(barcodeStr.slice(-1), 10);
  }
}

Vue.use(uuid);

export default {
  name: "Forms",
  computed: {
    isValid() {
      return !this.$v.coupon.$invalid;
    }
  },
  mixins: [validationMixin],
  validations: {
    coupon: {
      title: {
        required
      },
      start_date: {
        required
      },
      end_date: {
        required
      },
      jan_code: {
        required,
        numeric,
        checkJanCodeLength
      },
      discount: {
        required,
        numeric,
        minValue: minValue(1)
      },
      limitNum: {
        required,
        numeric,
        minValue: minValue(0)
      },
      priorityNumber: {
        required,
        numeric,
        minValue: minValue(1)
      },
    }
  },
  data() {
    return {
      selected: [], // Must be an array reference!
      show: true,
      horizontal: { label: "col-3", input: "col-9" },
      options: [
        { value: 1, label: "公開" },
        { value: 2, label: "非公開" },
        { value: 3, label: "終了" }
      ],
      selectOptions: [
        "Option 1",
        "Option 2",
        "Option 3",
        {
          value: ["some value", "another value"],
          label: "Selected option"
        }
      ],
      selectedOption: ["some value", "another value"],

      formCollapsed: true,
      checkboxNames: [
        "Checkboxes",
        "Inline Checkboxes",
        "Checkboxes - custom",
        "Inline Checkboxes - custom"
      ],
      radioNames: [
        "Radios",
        "Inline Radios",
        "Radios - custom",
        "Inline Radios - custom"
      ],
      couponOrigin: {
        id: "",
        created: "",
        created_user_id: "",
        limitNum: 0,
        discount: 0,
        enabled: true,
        end_date: "",
        image: "",
        jan_code: "",
        modified: "",
        notice: "",
        start_date: "",
        title: "",
        subTitle: "",
        membersOnly: true,
        reacquisitionFlag: true,
        onePerCustomerFlag: false,
        allPerCustomerFlag: true, // onePerCustomerFlagの逆
        wholesaleFlag: false,
        distributionFlag: false,
        status: 1,
        priorityNumber: 1,
        maker_id: null,
        wholesale_id: null,
        distribution_ids: null,
        isAlcohol: false,
      },
      account: {},
      coupon: {},
      couponImage: null,
      isNew: true,
      old_start_date: "",
      old_end_date: "",
      genrePullDown: [],
      categoryPullDown: [],
      makerPullDown: [],
      wholesalePullDown: [],
      distributionPullDown: [],
      fireStorageImageUrl: "",
      dangerModal: false,
      warnModal: false,
      disabledWholesaleFlag: false,
      distributionId: null,
      distributions: [],
      mailDistributions: [],
      genre_id: null,
      category_id: null,
      distributionEditFlag: false,
    };
  },
  methods: {
    isAdmin() {
      return this.account.role == UserRole.ADMIN;
    },
    checkIfValid(fieldName) {
      const field = this.$v.coupon[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    validator(val) {
      return val ? val.length >= 4 : false;
    },
    async init() {
      // 初期化
      this.isNew = true;
      this.coupon = JSON.parse(JSON.stringify(this.couponOrigin));
      this.couponImage = null;
      this.fireStorageImageUrl = "";
      this.distributions = [];
      this.mailDistributions = [];

      // ユーザーデータ取得
      let uid = firebase.auth().currentUser.uid
      const userDoc = await firebase.firestore().collection('users').doc(uid).get()
      const parent_id = userDoc.data().parent_id
      if (parent_id) {
        uid = parent_id
      }
      let event = firebase.app().functions('asia-northeast1').httpsCallable('me')
      await event({uid: uid}).then(function(res) {
        this.account = res.data.user
        this.$store.commit("hideLoading")
      }.bind(this));


      // プルダウン用のジャンル一覧取得
      const genres = await firebase.firestore().collection("genres").get()
      this.genrePullDown = genres.docs.map(genre => { return { label: genre.data().name, value: genre.id } })

      // プルダウン用のメーカー一覧取得
      firebase
        .firestore()
        .collection("users")
        .where("role", "==", UserRole.MAKERS)
        .get()
        .then(
          function(result) {
            let tmp = [];
            const account = this.account
            result.forEach(function(doc) {
              const isMakers = (account.role == UserRole.MAKERS)
              const me = (doc.id == account.id)
              const parentId = doc.data().parent_id
              // メーカーアカウントの場合、自身のメーカーのみ表示
              if ((!isMakers || me) && (parentId == null || parentId == "")) {
                tmp.push({ label: doc.data().name, value: doc.data().id });
              }
            });
            this.makerPullDown = tmp.sort((x, y) => x.label.localeCompare(y.label, 'ja'));
          }.bind(this)
        );

      // プルダウン用の卸一覧取得
      firebase
        .firestore()
        .collection("users")
        .where("role", "==", UserRole.WHOLESALE)
        .get()
        .then(
          function(result) {
            let tmp = [];
            const account = this.account
            result.forEach(function(doc) {
              const isWholesale = (account.role == UserRole.WHOLESALE)
              const me = (doc.id == account.id)
              const parentId = doc.data().parent_id
              // 卸アカウントの場合、自身の卸のみ表示
              if ((!isWholesale || me) && (parentId == null || parentId == "")) {
                tmp.push({ label: doc.data().name, value: doc.data().id });
              }
            });
            this.wholesalePullDown = tmp.sort((x, y) => x.label.localeCompare(y.label, 'ja'));
          }.bind(this)
        );
      
      // idありの場合は編集なので取得処理
      if (typeof this.$route.params.id !== "undefined") {
        const id = this.$route.params.id;
        await firebase
          .firestore()
          .collection("coupons")
          .doc(id)
          .get()
          .then(
            function(doc) {
              if (doc.exists === false) {
                return;
              } else {
                this.isNew = false;
                this.coupon = doc.data();

                if (this.coupon.subTitle === undefined) {
                  this.coupon.subTitle = "";
                }

                if (this.coupon.limitNum === undefined) {
                  this.coupon.limitNum = 0;
                }

                if (this.coupon.membersOnly === undefined) {
                  this.coupon.membersOnly = true;
                }

                if (this.coupon.priorityNumber === undefined) {
                  this.coupon.priorityNumber = 1;
                }

                if (this.coupon.distributionFlag) {
                  this.distributionId = this.coupon.distribution_ids[0];
                }

                this.coupon.allPerCustomerFlag = !this.coupon.onePerCustomerFlag;

                // format
                if (this.coupon.start_date !== "") {
                  const startDate = this.coupon.start_date.toDate();
                  this.coupon.start_date =
                    startDate.getFullYear() +
                    "-" +
                    ("0" + (startDate.getMonth() + 1)).slice(-2) +
                    "-" +
                    ("0" + startDate.getDate()).slice(-2);
                }
                if (this.coupon.end_date !== "") {
                  const endDate = this.coupon.end_date.toDate();
                  this.coupon.end_date =
                    endDate.getFullYear() +
                    "-" +
                    ("0" + (endDate.getMonth() + 1)).slice(-2) +
                    "-" +
                    ("0" + endDate.getDate()).slice(-2);
                }
                // 履歴用
                this.old_start_date = this.coupon.start_date;
                this.old_end_date = this.coupon.end_date;

                // 画像URL取得
                if (this.coupon.image !== "") {
                  const storage = firebase.storage();
                  storage.ref(this.coupon.image).getDownloadURL().then(
                    function(url) {
                      this.fireStorageImageUrl = url + "?alt=media";
                    }.bind(this)
                  );
                }
              }
            }.bind(this)
          );

        if (this.coupon.genre) {
          const genre = await this.coupon.genre.get()
          this.genre_id = genre.id
          this.onUpdateGenre(this.genre_id)
        }
        if (this.coupon.category) {
          const category = await this.coupon.category.get()
          this.category_id = category.id
        }
        if (this.coupon.distributionFlag) {
          const uid = await this.getUid()
          this.distributionEditFlag = (uid == this.distributionId)
        }
      } else {
        this.distributionEditFlag = true
      }
      await this.fetchDistributions(this.$route.params.id);
    },
    async fetchDistributions(couponId) {
      // ひもづけ小売情報取得
      let distributionSnapshot = null;
      const uid = await this.getUid()
      const userSnapShot = await firebase.firestore()
        .collection("users")
        .doc(uid)
        .get();

      let distributionDocs = [];
      if (this.isAdmin()) {
        distributionSnapshot = await firebase.firestore().collection("users").where("role", "==", UserRole.DISTRIBUTIONS).get()
        distributionDocs = distributionSnapshot.docs
      } else if (this.isDistribution()) {
        distributionSnapshot = await firebase.firestore().collection('users').where("id", "==", uid).get()
        distributionDocs = distributionSnapshot.docs
      } else {
        // メーカーおよび卸権限では、小売アカウントの帳合登録で登録されている小売のみ表示
        const collaborates = await firebase.firestore().collection('collaborates').get()
        let distributionIds = []
        for (const collaborate of collaborates.docs) {
          if ((this.isMakers() && collaborate.data().maker_id == uid) || (this.isWholeSales() && collaborate.data().wholesale_id == uid)) {
            const distributionDoc = await firebase.firestore().collection('users').doc(collaborate.data().created_user_id).get()
            if (distributionDoc.exists) {
              const parentId = distributionDoc.data().parent_id
              if (parentId != null && parentId != "") {
                // サブアカウントの場合
                const parentDistributionDoc = await firebase.firestore().collection('users').doc(parentId).get()
                if (parentDistributionDoc.exists) {
                  if (!distributionIds.some(d => d == parentDistributionDoc.id)) {
                    distributionIds.push(parentDistributionDoc.id)
                    distributionDocs.push(parentDistributionDoc)
                  }
                }
              } else {
                if (!distributionIds.some(d => d == distributionDoc.id)) {
                  distributionIds.push(distributionDoc.id)
                  distributionDocs.push(distributionDoc)
                }
              }
            }
          }
        }
      }

      let storeCouponsSnapshot = null;
      if (typeof couponId !== "undefined") {
        storeCouponsSnapshot = await firebase.firestore().collectionGroup("storeCoupons").where("id", "==", couponId).get()
      }

      for (const distributionDoc of distributionDocs) {
        let distribution = {}
        const distributionUser = distributionDoc.data()
        distribution.id = distributionDoc.id
        distribution.name = distributionUser.name
        const parentId = distributionUser.parent_id
        if (parentId != null && parentId != "") {
          continue;
        }
        this.distributionPullDown.push({ label: distribution.name, value: distribution.id });
        let mailDistribution = Object.assign({}, distribution)
        mailDistribution.checked = this.coupon.distribution_ids != null && this.coupon.distribution_ids.some(d => d == mailDistribution.id)
        this.mailDistributions.push(mailDistribution)

        if (this.isAdmin() || this.isWholeSales() || this.isDistribution() ||this.isMakers()) {
          const storesSnapshot = await firebase.firestore().collection('stores').where('companyCode', '==', distributionUser.companyCode).get()
          let stores = []
          for (const storeDoc of storesSnapshot.docs) {
            let store = {}
            store.id = storeDoc.id
            store.storeCode = storeDoc.data().storeCode
            store.name = storeDoc.data().name
            if (typeof couponId !== "undefined") {
              store.hasCoupon = false
              for (const storeCouponDoc of storeCouponsSnapshot.docs) {
                const parentRef = storeCouponDoc.ref.parent.parent;
                if (parentRef.id == storeDoc.id) {
                  store.hasCoupon = true
                  break
                }
              }
            } else {
              store.hasCoupon = true
            }
            stores.push(store)
          }
          distribution.stores = stores.sort((a, b) => Number(a.storeCode) - Number(b.storeCode))
          distribution.useId = "use_" + distribution.id
          distribution.hasCoupon = false
          if (this.coupon.use_distribution_ids != null) {
            distribution.hasCoupon = this.coupon.use_distribution_ids.some(d => d == distribution.id)
          }
          if (stores.length > 0) {
            this.distributions.push(distribution)
          }
        }
      }
    },
    async saveCoupon() {
      this.$store.commit("showLoading");

      // 新規の場合はID発行
      if (this.isNew) {
        this.coupon.id = uuid.v4();
        const uid = await this.getUid()
        this.coupon.created_user_id = uid
        this.coupon.sortKey = uuid.v4()
      }

      // 画像が選択されていれば先に画像のアップロード
      if (this.couponImage !== null) {
        const imageName = uuid.v4();
        const fileType = this.couponImage.name.split(".").slice(-1)[0];
        // アップロード
        const imagePath = "/image/coupon/" + this.coupon.id + "-" + this.coupon.jan_code + "." + fileType;
        let imageResult = await firebase
          .storage()
          .ref(imagePath)
          .put(this.couponImage);
        this.coupon.image = imagePath;
      }

      // 卸発行クーポンでない場合、卸IDいれない
      if (!this.coupon.wholesaleFlag) {
        this.coupon.wholesale_id = null
      }

      let saveData = JSON.parse(JSON.stringify(this.coupon));

      // format
      if (this.coupon.start_date !== "") {
        saveData.start_date = new Date(this.coupon.start_date);
      }
      if (this.coupon.end_date !== "") {
        saveData.end_date = new Date(this.coupon.end_date);
      }
      saveData.limitNum = Number(saveData.limitNum);
      saveData.discount = Number(saveData.discount);
      saveData.priorityNumber = Number(saveData.priorityNumber);
      saveData.onePerCustomerFlag = !this.coupon.allPerCustomerFlag;
      if (this.isNew) {
        saveData.created = firebase.firestore.FieldValue.serverTimestamp();
      } else {
        saveData.modified = firebase.firestore.FieldValue.serverTimestamp();
      }
      if (this.coupon.distributionFlag) {
        saveData.distribution_ids = [this.distributionId];
      } else {
        saveData.distribution_ids = this.mailDistributions.filter(md => md.checked).map(md => md.id);
      }
      saveData.use_distribution_ids = this.distributions.filter(d => d.hasCoupon).map(d => d.id);

      // ref
      if (this.genre_id != null) {
        const genre = await firebase.firestore().collection('genres').doc(this.genre_id).get()
        if (genre.exists) saveData.genre = genre.ref
      }
      if (this.category_id != null) {
        const category = await firebase.firestore().collection('categories').doc(this.category_id).get()
        if (category.exists) saveData.category = category.ref
      }

      // クーポンコード生成
      if (!this.coupon.distributionFlag) {
        const incrementsDocName = "coupon_code_" + saveData.jan_code;
        const incrementsDoc = await firebase.firestore().collection("increments").doc(incrementsDocName).get();
        saveData.couponCode = incrementsDoc.exists ? incrementsDoc.data().count : 1;

        for (;;) {
          let existCoupon = await firebase.firestore().collection("coupons")
            .where("jan_code", "==", saveData.jan_code)
            .where("couponCode", "==", saveData.couponCode)
            .get();
          if (existCoupon.size > 0 && !existCoupon.docs.some(d => d.id == this.coupon.id)) {
            saveData.couponCode += 1;
            continue;
          }
          break;
        }
         // インクリメント更新
        await firebase.firestore().collection("increments").doc(incrementsDocName).set({ count: saveData.couponCode });
      } else {
        saveData.couponCode = Number("1" + saveData.discount.toString().padStart(5, '0'));
        let existCoupon = await firebase.firestore().collection("coupons")
            .where("jan_code", "==", saveData.jan_code)
            .where("couponCode", "==", saveData.couponCode)
            .get();
        if (existCoupon.size > 0 && existCoupon.docs.some(d => d.id != this.coupon.id)) {
          this.$store.commit("hideLoading");
          return;
        }
      }

      // 期間変更の場合履歴を残す
      if (!this.isNew && (this.old_start_date != this.coupon.start_date || this.old_end_date != this.coupon.end_date)) {
        var archivedData = await firebase
          .firestore()
          .collection("coupons")
          .doc(this.coupon.id)
          .get();
        await firebase
          .firestore()
          .collection("archivedCoupons")
          .add(archivedData.data());
      }

      // ステータスを非公開or終了にした、あるいは期間を当日から外した場合、getCouponsを削除、expiredCouponsに移行
      const startDate = new Date(this.coupon.start_date);
      const endDate = new Date(this.coupon.end_date);
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(0, 0, 0, 0);
      if (!this.isNew && (startDate.getTime() > today.getTime() || endDate.getTime() < today.getTime() || this.coupon.status == 2 || this.coupon.status == 3)) {
        var getCouponsData = await firebase.firestore().collection("getCoupons").where("id", '==', this.coupon.id).get();
        getCouponsData.forEach((d) => {
          var data = d.data();
          firebase.firestore().collection("expiredCoupons").add(data);
          firebase.firestore().collection("getCoupons").doc(d.id).delete();
        })
      }

      // 保存
      await firebase
        .firestore()
        .collection("coupons")
        .doc(this.coupon.id)
        .set(saveData);

      await this.saveStore();

      this.$store.commit("hideLoading");

      if (this.isNew) {
        let distributions = this.mailDistributions.filter(md => md.checked)
        if (distributions.length > 0) {
          const event = firebase.app().functions('asia-northeast1').httpsCallable('sendCouponMail')
          event({ couponData: saveData, distributions: distributions })
        }
      }

      // 保存後は一覧画面に遷移
      const link = `/coupons`;
      this.$router.push({ path: link });
    },
    async saveStore() {
      // 店舗登録
      if (this.distributions.length > 0) {
        const couponId = this.coupon.id        
        for (var distribution of this.distributions) {
          for (var store of distribution.stores) {
            if (!store.hasCoupon) {
              firebase.firestore().collection("stores").doc(store.id).collection('storeCoupons').doc(couponId).delete()
            } else {
              // 同じjan_codeのクーポンは同一店舗で同一期間で共存できないので古いものを削除する
              var coupon = await firebase.firestore().collection('coupons').doc(couponId).get()
              var sameJanCodeCoupons = await firebase.firestore().collection('coupons').where('jan_code', '==', coupon.get('jan_code')).get()
              for (var sameJanCodeCoupon of sameJanCodeCoupons.docs) {
                var startDate1 = coupon.get('start_date') != null ? coupon.get('start_date').toDate() : null;
                var endDate1 = coupon.get('end_date') != null ? coupon.get('end_date').toDate() : null;
                var startDate2 = sameJanCodeCoupon.get('start_date') != null ? sameJanCodeCoupon.get('start_date').toDate() : null;
                var endDate2 = sameJanCodeCoupon.get('end_date') != null ? sameJanCodeCoupon.get('end_date').toDate() : null;
                if(this.isDateOverlap(startDate1, endDate1, startDate2, endDate2)) {
                  firebase.firestore().collection('stores').doc(store.id).collection('storeCoupons').doc(sameJanCodeCoupon.id).delete()
                }
              }
              let tmp = {}
              tmp.id = couponId
              firebase.firestore().collection('stores').doc(store.id).collection('storeCoupons').doc(couponId).set(tmp)
            }
          }
        }
      }
    },
    submit() {
      this.$v.$touch();
      if (!this.isValid) {
        return;
      }
      this.saveCoupon();
    },
    async submitStore() {
      this.$v.$touch();
      if (!this.isValid) {
        return;
      }
      this.$store.commit("showLoading");
      await this.saveStore();
      this.$store.commit("hideLoading");
      // 保存後は一覧画面に遷移
      const link = `/coupons`;
      this.$router.push({ path: link });
    },
    onChangeImage(files, e) {
      this.couponImage = files[0];
    },
    onUpdateStatus(value) {
      this.coupon.status = value;
    },
    onUpdateMaker(value) {
      this.coupon.maker_id = value;
    },
    async onUpdateGenre(value) {
      const genre = await firebase.firestore().collection("genres").doc(value).get()
      const categories = await firebase.firestore().collection("categories").where("genre", "==", genre.ref).where("all", "==", false).get()
      this.categoryPullDown = categories.docs.map(category => { return { label: category.data().name, value: category.id } })
    },
    onUpdateWholesale(value) {
      this.coupon.wholesale_id = value;
    },
    onClickedAllStoreCoupon(checked, id) {
      for (var distribution of this.distributions) {
        if (distribution.id == id) {
          for (var store of distribution.stores) {
            store.hasCoupon = checked
          }
          break
        }
      }
    },
    isDateOverlap(startDate1, endDate1, startDate2, endDate2) {
      // 日付のみで比較する
      startDate1 = startDate1 != null ? new Date(startDate1.getFullYear(), startDate1.getMonth(), startDate1.getDate()) : null;
      endDate1 = endDate1 != null ? new Date(endDate1.getFullYear(), endDate1.getMonth(), endDate1.getDate()) : null;
      startDate2 = startDate2 != null ? new Date(startDate2.getFullYear(), startDate2.getMonth(), startDate2.getDate()) : null;
      endDate2 = endDate2 != null ? new Date(endDate2.getFullYear(), endDate2.getMonth(), endDate2.getDate()) : null;

      if (startDate1 == null) {
        if (endDate1 == null) return true;
        if (startDate2 == null) return true;
        return endDate1 >= startDate2;
      } else if (endDate1 == null) {
        if (endDate2 == null) return true;
        return startDate1 <= endDate2;
      } else if (startDate2 == null) {
        if (endDate2 == null) return true;
        return startDate1 <= endDate2 && endDate2 <= endDate1;
      } else if (endDate2 == null) {
        return startDate1 <= startDate2 && startDate2 <= endDate1;
      }
      return startDate1 <= endDate2 && startDate2 <= endDate1;
    },
    async onClickedDelete() {
      const usages = await firebase.firestore().collection("usages").where("id", '==', this.coupon.id).get();
      if (!this.warnModal && usages.size > 0) {
        this.dangerModal = false
        this.warnModal = true
        return
      }

      this.$store.commit("showLoading");
      // 削除 (storeCoupons、getCouponsも削除)
      firebase
        .firestore()
        .collectionGroup("storeCoupons")
        .where("id", "==", this.coupon.id)
        .get()
        .then(function(querySnapshot) {
          querySnapshot.forEach(function(doc) {
            doc.ref.delete();
          });
        })
        .catch(function(error) {
          console.log("Error getting documents: ", error);
        });
      var getCouponsData = await firebase.firestore().collection("getCoupons").where("id", '==', this.coupon.id).get();
      getCouponsData.forEach((d) => {
        firebase.firestore().collection("getCoupons").doc(d.id).delete();
      });
      await firebase
        .firestore()
        .collection("coupons")
        .doc(this.coupon.id)
        .delete();
      this.$store.commit("hideLoading");

      // 削除後は一覧画面に遷移
      const link = `/coupons`;
      this.$router.push({ path: link });
    },
    getDisplayImageName() {
      return this.coupon.image.replace("/image/coupon/", "");
    },
    async setDisabled() {
      const uid = await this.getUid()
      const userResult = await firebase.firestore().collection('users').doc(uid).get();
      const user = userResult.data();
      if (user.role == UserRole.MAKERS) {
        if (this.isNew) {
          this.coupon.maker_id = user.id
        } 
      }
      if (user.role == UserRole.WHOLESALE) {
        this.disabledWholesaleFlag = false
        if (this.isNew) {
          this.coupon.wholesaleFlag = true
          this.coupon.wholesale_id = user.id
        }
      }
      if (user.role == UserRole.DISTRIBUTIONS) {
        this.disabledWholesaleFlag = true
        this.coupon.distributionFlag = true
        if (this.isNew) {
          this.distributionId = user.id
        }
      }
    },
    async getUid() {
      let uid
      uid = firebase.auth().currentUser.uid
      const userDoc = await firebase.firestore().collection('users').doc(uid).get()
      const parent_id = userDoc.data().parent_id
      if (parent_id) {
        uid = parent_id
      }
      return uid
    },
    isDistribution() {
      return this.account.role == UserRole.DISTRIBUTIONS
    },
    isMakers() {
      return this.account.role == UserRole.MAKERS
    },
    isWholeSales() {
      return this.account.role == UserRole.WHOLESALE
    },
    isRegister() {
      return this.account.role == UserRole.REGISTER
    },
  },
  created() {
    this.init();
  },
  watch: {
    $route: function() {
      this.init();
    }
  },
  mounted() {
    this.setDisabled();
  }
};
</script>
